import React, {FC} from 'react';
import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import RequestError from './RequestError';
import './styles.scss';

const ApiDocumentation: FC = () => {
  const navLinks = [
    {name: 'Get all your workflows', link: '#get-all-workflows'},
    {name: 'Get workflow logs', link: '#get-workflow-logs'},
    {name: 'Get completed workflow data', link: '#get-completed-workflow-data'},
    {name: 'Delete workflow', link: '#delete-workflow'},
    {name: 'Update workflows status', link: '#update-workflow-status'},
  ];

  return (
    <Layout>
      <PageHero title="API Documentation" subtitle="This page provides API documentation for workflows" titleSmall />
      <div className="d-lg-flex">
        <div className="sidebar-navigation d-none d-lg-block">
          <ul className="sidebar-navigation-list">
            <h5>API requests</h5>
            {navLinks.map(item => (
              <li
                className={
                  global &&
                  global.window &&
                  global.window.location &&
                  global.window.location.href &&
                  global.window.location.href.includes(item.link)
                    ? 'active'
                    : ''
                }
              >
                <a href={item.link}>{item.name}</a>
              </li>
            ))}
          </ul>
        </div>
        <Section>
          <div className="col-12 d-block mb_20 api-doc" style={{maxWidth: '1200px', width: '100%'}}>
            <h4 className="info-title" id="get-all-workflows">
              To get all your workflows use this get API.
            </h4>
            <h5>HTTP Request</h5>
            <pre className="pre">
              <p>
                <span className="btn-pre">GET</span>{' '}
                https://api.hexomatic.com/v2/app/services/v1/workflows?key=&#123;YOUR_API_KEY&#125;&limit=&#123;LIMIT&#125;&offset=&#123;OFFSET&#125;
              </p>
            </pre>
            <h5>Parameters</h5>
            <table>
              <thead>
                <tr>
                  <th>Fields</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="td-fill">YOUR_API_KEY</td>
                  <td>
                    <p className="bold-text">string</p>
                    <p className="normal-text">
                      {' '}
                      to get YOUR_API_KEY visit hexomatic{' '}
                      <a href="https://dash.hexomatic.com/settings" no-index>
                        settings
                      </a>{' '}
                      and copy your API key
                      {/* ! change  */}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="td-fill">LIMIT</td>
                  <td>
                    <p className="bold-text">number</p>
                    <p className="normal-text">
                      this filed is optional and indicates the number of workflows to be received as a result
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="td-fill">OFFSET</td>
                  <td>
                    <p className="bold-text">number</p>
                    <p className="normal-text">
                      this filed is optional and indicates the point starting from where the workflows will be provided
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>

            <h5>Response Body</h5>
            <p>if request is successful you will get</p>
            <div className="json-title-block">
              <span>JSON representation</span>
            </div>
            <pre className="pre">
              <p>{'{'}</p>
              <p className="mgl-25">"error": false,</p>
              <p className="mgl-25">"workflows": [</p>
              <p className="mgl-50"> Workflow</p>
              <p className="mgl-25">]</p>
              <p>{'}'}</p>
            </pre>
            <table>
              <thead>
                <tr>
                  <th>Fields</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="td-fill">error</td>
                  <td>
                    <p className="bold-text">boolean</p>
                    <p className="normal-text">if the error returns false, it means that no error has occurred</p>
                  </td>
                </tr>
                <tr>
                  <td className="td-fill">Workflow []</td>
                  <td>
                    <p className="bold-text">
                      object(<span className="normal-text">Workflow</span>)
                    </p>
                    <p className="normal-text">
                      Workflow represents a workflow object
                      {/* change */}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <h5>Workflow</h5>
            <p>workflow object structure</p>
            <div className="json-title-block">
              <span>JSON representation</span>
            </div>
            <pre className="pre">
              <p>Workflow = {'{'}</p>
              <p className="mgl-25">"id": Number,</p>
              <p className="mgl-25">"name": String,</p>
              <p className="mgl-25">"active": Boolean,</p>

              <p className="mgl-25">"steps": {'['}</p>
              <p className="mgl-50">{'{'}</p>
              <p className="mgl-75">"index": Number,</p>
              <p className="mgl-75">"automation_name": String</p>
              <p className="mgl-50">{'}'}</p>
              <p className="mgl-25">{'],'}</p>

              <p className="mgl-25">"execution_data": {'{'}</p>
              <p className="mgl-75">"status": String</p>
              <p className="mgl-25">{'},'}</p>

              <p className="mgl-25">"created_at": String,</p>
              <p className="mgl-25">"updated_at": String</p>
              <p>{'}'}</p>
            </pre>
            <table>
              <thead>
                <tr>
                  <th>Fields</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="td-fill">id</td>
                  <td>
                    <p className="bold-text">number</p>
                    <p className="normal-text">workflow id</p>
                  </td>
                </tr>

                <tr>
                  <td className="td-fill">name</td>
                  <td>
                    <p className="bold-text">string</p>
                    <p className="normal-text">workflow name</p>
                  </td>
                </tr>
                <tr>
                  <td className="td-fill">active</td>
                  <td>
                    <p className="bold-text">boolean</p>
                    <p className="normal-text">is workflow scheduled</p>
                  </td>
                </tr>
                <tr>
                  <td className="td-fill">steps</td>
                  <td>
                    <p className="bold-text">object</p>
                    <p className="normal-text">list of automations included in workflow (automation id and name )</p>
                  </td>
                </tr>
                <tr>
                  <td className="td-fill">execution_data</td>
                  <td>
                    <p className="bold-text">object</p>
                    <p className="normal-text">current status of the workflow</p>
                  </td>
                </tr>
                <tr>
                  <td className="td-fill">created_at</td>
                  <td>
                    <p className="bold-text">string</p>
                    <p className="normal-text">created date of the workflow</p>
                  </td>
                </tr>
                <tr>
                  <td className="td-fill">updated_at</td>
                  <td>
                    <p className="bold-text">string</p>
                    <p className="normal-text">updated date of the workflow</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <h5>Example</h5>
            <pre className="pre">
              <p>object {'{'}</p>
              <p className="mgl-25">"error": false,</p>
              <p className="mgl-25">"workflows": {'['}</p>

              <p className="mgl-50">{'{'}</p>

              <p className="mgl-75">"id": 6,</p>
              <p className="mgl-75">"name": "New workflow 02.07.21 01:50:50",</p>
              <p className="mgl-75">"active": false,</p>

              <p className="mgl-75">"steps": {'['}</p>
              <p className="mgl-100">{'{'}</p>
              <p className="mgl-125">"index": 0,</p>
              <p className="mgl-125">"automation_name": "data_input"</p>
              <p className="mgl-100">{'}'}</p>
              <p className="mgl-75">{'],'}</p>

              <p className="mgl-75">"execution_data": {'{'}</p>
              <p className="mgl-100">"status": ""</p>
              <p className="mgl-75">{'},'}</p>

              <p className="mgl-75">"created_at": "2021-07-02T13:51:08.023Z",</p>
              <p className="mgl-75">"updated_at": "2021-07-02T13:51:18.738Z"</p>
              <p className="mgl-50">{'}'}</p>

              <p className="mgl-25">{']'}</p>
              <p>{'}'}</p>
            </pre>

            <RequestError />
            <h4 className="info-title" id="get-workflow-logs">
              To get workflow logs use this API.
            </h4>
            <h5>HTTP Request</h5>
            <pre className="pre">
              <p>
                <span className="btn-pre">GET</span>{' '}
                https://api.hexomatic.com/v2/app/services/v1/workflow-logs?workflow_id=&#123;YOUR_WORKFLOW_ID&#125;&key=&#123;YOUR_API_KEY&#125;
              </p>
            </pre>
            <h5>Parameters</h5>
            <table>
              <thead>
                <tr>
                  <th>Fields</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="td-fill">YOUR_API_KEY</td>
                  <td>
                    <p className="bold-text">string</p>
                    <p className="normal-text">
                      {' '}
                      to get YOUR_API_KEY visit{' '}
                      <a href="https://dash.hexomatic.com/settings" no-index>
                        {' '}
                        settings{' '}
                      </a>
                      and copy your API key
                      {/* ! change  */}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="td-fill">YOUR_WORKFLOW_ID</td>
                  <td>
                    <p className="bold-text">string</p>
                    <p className="normal-text">
                      if you did get workflows request you must have recieved all the workflows' ids, you can take
                      YOUR_WORKFLOW_ID from there {/* ! change  */}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <h5>Response Body</h5>
            <p>if request is successful you will get</p>
            <div className="json-title-block">
              <span>JSON representation</span>
            </div>
            <pre className="pre">
              <p>{'{'}</p>
              <p className="mgl-25">"error": false,</p>
              <p className="mgl-25">"logs": [</p>
              <p className="mgl-50"> Log</p>
              <p className="mgl-25">]</p>
              <p>{'}'}</p>
            </pre>
            <table>
              <thead>
                <tr>
                  <th>Fields</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="td-fill">error</td>
                  <td>
                    <p className="bold-text">boolean</p>
                    <p className="normal-text">if the error returns false, it means that no error has occurred</p>
                  </td>
                </tr>
                <tr>
                  <td className="td-fill">Log []</td>
                  <td>
                    <p className="bold-text">
                      object(<span className="normal-text">Log</span>)
                    </p>
                    <p className="normal-text">
                      Log represents a log object
                      {/* change */}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <h5>Log</h5>
            <p>Log object structure</p>
            <div className="json-title-block">
              <span>JSON representation</span>
            </div>
            <pre className="pre">
              <p>Log = {'{'}</p>
              <p className="mgl-25">"log": String,</p>
              <p className="mgl-25">"type": String</p>
              <p>{'}'}</p>
            </pre>
            <table>
              <thead>
                <tr>
                  <th>Fields</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="td-fill">log</td>
                  <td>
                    <p className="bold-text">string</p>
                    <p className="normal-text">date when log was created</p>
                  </td>
                </tr>

                <tr>
                  <td className="td-fill">type</td>
                  <td>
                    <p className="bold-text">string</p>
                    <p className="normal-text">description of the done operation</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <h5>Example</h5>
            <pre className="pre">
              <p>object {'{'}</p>
              <p className="mgl-25">"error": false,</p>
              <p className="mgl-25">"logs": {'['}</p>
              <p className="mgl-50">{'{'}</p>
              <p className="mgl-75">"log": "2021-07-02T13:51:08.023Z",</p>
              <p className="mgl-75">"type": "data_input started"</p>
              <p className="mgl-50">{'},'}</p>
              <p className="mgl-50">{'{'}</p>
              <p className="mgl-75">"log": "2021-07-02T13:51:08.023Z",</p>
              <p className="mgl-75">"type": "Passing input data"</p>
              <p className="mgl-50">{'}'}</p>
              <p className="mgl-25">{']'}</p>
              <p>{'}'}</p>
            </pre>
            <RequestError />

            <h4 className="info-title" id="get-completed-workflow-data">
              To get completed workflow data use this API.
            </h4>
            <h5>HTTP Request</h5>
            <pre className="pre">
              <p>
                <span className="btn-pre">GET</span>{' '}
                https://api.hexomatic.com/v2/app/services/v1/workflows/&#123;YOUR_WORKFLOW_ID&#125;?key=&#123;YOUR_API_KEY&#125;
              </p>
            </pre>
            <h5>Parameters</h5>
            <table>
              <thead>
                <tr>
                  <th>Fields</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="td-fill">YOUR_API_KEY</td>
                  <td>
                    <p className="bold-text">string</p>
                    <p className="normal-text">
                      {' '}
                      to get YOUR_API_KEY visit{' '}
                      <a href="https://dash.hexomatic.com/settings" no-index>
                        {' '}
                        settings{' '}
                      </a>
                      and copy your API key
                      {/* ! change  */}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="td-fill">YOUR_WORKFLOW_ID</td>
                  <td>
                    <p className="bold-text">string</p>
                    <p className="normal-text">
                      if you did get workflows request you must have recieved all the workflows' ids, you can take
                      YOUR_WORKFLOW_ID from there {/* ! change  */}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <h5>Response Body</h5>
            <p>if request is successful you will get</p>
            <div className="json-title-block">
              <span>JSON representation</span>
            </div>
            <pre className="pre">
              <p>{'{'}</p>
              <p className="mgl-25">"error": false,</p>
              <p className="mgl-25">"workflows": {'{'}</p>
              <p className="mgl-50"> WorkflowsData</p>
              <p className="mgl-25">{'}'}</p>
              <p>{'}'}</p>
            </pre>
            <table>
              <thead>
                <tr>
                  <th>Fields</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="td-fill">error</td>
                  <td>
                    <p className="bold-text">boolean</p>
                    <p className="normal-text">if the error returns false, it means that no error has occurred</p>
                  </td>
                </tr>
                <tr>
                  <td className="td-fill">workflow</td>
                  <td>
                    <p className="bold-text">
                      object(<span className="normal-text">WorkflowsData</span>)
                    </p>
                    <p className="normal-text">
                      WorkflowsData represents a WorkflowsData object
                      {/* change */}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <h5>WorkflowsData</h5>
            <p>WorkflowsData object structure</p>
            <div className="json-title-block">
              <span>JSON representation</span>
            </div>
            <pre className="pre">
              <p>WorkflowsData = {'{'}</p>
              <p className="mgl-25">"id": Number,</p>
              <p className="mgl-25">"name": String</p>
              <p className="mgl-25">"active": Boolean</p>
              <p className="mgl-25">"status": String</p>
              <p className="mgl-25">"data": {'{'}</p>

              <p className="mgl-50">"content": [</p>
              <p className="mgl-75">String[]</p>
              <p className="mgl-50">],</p>

              <p className="mgl-50">"headers": [</p>
              <p className="mgl-75">String[]</p>
              <p className="mgl-50">]</p>

              <p className="mgl-25">{'}'},</p>

              <p>{'}'}</p>
            </pre>
            <table>
              <thead>
                <tr>
                  <th>Fields</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="td-fill">id</td>
                  <td>
                    <p className="bold-text">number</p>
                    <p className="normal-text">workflow id</p>
                  </td>
                </tr>

                <tr>
                  <td className="td-fill">name</td>
                  <td>
                    <p className="bold-text">string</p>
                    <p className="normal-text">workflow name</p>
                  </td>
                </tr>
                <tr>
                  <td className="td-fill">active</td>
                  <td>
                    <p className="bold-text">boolean</p>
                    <p className="normal-text">is workflow active</p>
                  </td>
                </tr>
                <tr>
                  <td className="td-fill">status</td>
                  <td>
                    <p className="bold-text">string</p>
                    <p className="normal-text">workflow status</p>
                  </td>
                </tr>
                <tr>
                  <td className="td-fill">data</td>
                  <td>
                    <p className="bold-text">object</p>
                    <p className="normal-text">workflow data</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <h5>Example</h5>
            <pre className="pre">
              <p>object {'{'}</p>
              <p className="mgl-25">"error": false,</p>
              <p className="mgl-25">"workflow": {'{'}</p>
              <p className="mgl-50">"id": 1,</p>
              <p className="mgl-50">"name": "New workflow 01.11.21 05:50:30",</p>
              <p className="mgl-50">"active": false,</p>
              <p className="mgl-50">"status": "completed",</p>

              <p className="mgl-50">"data": {'{'}</p>
              <p className="mgl-75">"content": [</p>
              <p className="mgl-100">[</p>
              <p className="mgl-125">"https:/example.com/category/23",</p>
              <p className="mgl-125">"https:/example.com/item/16764253"</p>
              <p className="mgl-100">],</p>
              <p className="mgl-100">[</p>
              <p className="mgl-125">"",</p>
              <p className="mgl-125">"https:/example.com/item/16883183"</p>
              <p className="mgl-100">]</p>
              <p className="mgl-75">],</p>

              <p className="mgl-75">"headers": [</p>
              <p className="mgl-75">[</p>
              <p className="mgl-100">"__url",</p>
              <p className="mgl-100">"propertyName1"</p>
              <p className="mgl-75">]</p>

              <p className="mgl-50">{'}'}</p>
              <p className="mgl-25">{'}'}</p>
              <p>{'}'}</p>
            </pre>
            <RequestError />
            <h4 className="info-title" id="delete-workflow">
              To delete workflow use this API.
            </h4>
            <h5>HTTP Request</h5>
            <pre className="pre">
              <p>
                <span className="btn-pre">DELETE</span>{' '}
                https://api.hexomatic.com/v2/app/services/v1/workflows?key=&#123;YOUR_API_KEY&#125;
              </p>
            </pre>
            <h5>Parameters</h5>
            <table>
              <thead>
                <tr>
                  <th>Fields</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="td-fill">YOUR_API_KEY</td>
                  <td>
                    <p className="bold-text">string</p>
                    <p className="normal-text">
                      {' '}
                      to get YOUR_API_KEY visit{' '}
                      <a href="https://dash.hexomatic.com/settings" no-index>
                        settings{' '}
                      </a>
                      and copy your API key
                      {/* ! change  */}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <h5>Request Body</h5>
            <table>
              <thead>
                <tr>
                  <th>Fields</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="td-fill">workflows_ids</td>
                  <td>
                    <p className="bold-text">
                      array(<span className="normal-text">ids</span>)
                    </p>
                    <p className="normal-text">
                      you should provide array of id or ids to select specific workflow/workflows
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <h5>Response Body</h5>
            <p>if request is successful you will get</p>
            <div className="json-title-block">
              <span>JSON representation</span>
            </div>
            <pre className="pre">
              <p>{'{'}</p>
              <p className="mgl-25">"error": false</p>
              <p>{'}'}</p>
            </pre>
            <table>
              <thead>
                <tr>
                  <th>Fields</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="td-fill">error</td>
                  <td>
                    <p className="bold-text">boolean</p>
                    <p className="normal-text">if the error returns false, it means that no error has occurred</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <RequestError />
            <h4 className="info-title" id="update-workflow-status">
              To update workflows status use the following API.
            </h4>

            <h5>HTTP Request</h5>
            <pre className="pre">
              <p>
                <span className="btn-pre">PUT</span>{' '}
                https://api.hexomatic.com/v2/app/services/v1/workflows?key=&#123;YOUR_API_KEY&#125;
              </p>
            </pre>
            <h5>Parameters</h5>
            <table>
              <thead>
                <tr>
                  <th>Fields</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="td-fill">YOUR_API_KEY</td>
                  <td>
                    <p className="bold-text">string</p>
                    <p className="normal-text">
                      {' '}
                      to get YOUR_API_KEY visit{' '}
                      <a href="https://dash.hexomatic.com/settings" no-index>
                        settings{' '}
                      </a>
                      and copy your API key
                      {/* ! change  */}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>

            <h5>Request Body</h5>
            <table>
              <thead>
                <tr>
                  <th>Fields</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="td-fill">workflows_ids</td>
                  <td>
                    <p className="bold-text">
                      array (<span className="normal-text">ids or id</span>)
                    </p>
                    <p className="normal-text">
                      you should provide array of id or ids to select specific workflow/workflows
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="td-fill">active</td>
                  <td>
                    <p className="bold-text">boolean</p>
                    <p className="normal-text">
                      you should provide true or false to change the selected workflow/workflows status
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <h5>Example</h5>
            <pre className="pre">
              <p>{'{'}</p>
              <p className="mgl-25">"workflows_ids": [351],</p>
              <p className="mgl-25">"active": true</p>
              <p>{'}'}</p>
            </pre>
            <h5>Response Body</h5>
            <p>if request is successful you will get</p>
            <div className="json-title-block">
              <span>JSON representation</span>
            </div>
            <pre className="pre">
              <p>{'{'}</p>
              <p className="mgl-25">"error": false</p>
              <p>{'}'}</p>
            </pre>
            <table>
              <thead>
                <tr>
                  <th>Fields</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="td-fill">error</td>
                  <td>
                    <p className="bold-text">boolean</p>
                    <p className="normal-text">if the error returns false, it means that no error has occurred</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <RequestError />
            {/* <Discovertechstack />
            <Discoverwhois /> */}
          </div>
        </Section>
      </div>
    </Layout>
  );
};

export default ApiDocumentation;
